export const formattedDateServer = function (d = new Date()) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return `${day}-${month}-${year}`;
};
export const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let flat = "";

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

export const SkDate = (SkDate) => {
  return SkDate?.toLocaleDateString("en-GB", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const SkPrice = (SkPrice) => {
  return SkPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "INR",
  });
};

export function formatDate(timestamp) {
  const currentDate = new Date();
  const targetDate = new Date(timestamp);

  // Check if the targetDate is yesterday
  const isYesterday =
    targetDate.getDate() === currentDate.getDate() - 1 &&
    targetDate.getMonth() === currentDate.getMonth() &&
    targetDate.getFullYear() === currentDate.getFullYear();

  // Get the time in 12-hour format
  const time = targetDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  if (isYesterday) {
    return `Yesterday at ${time}`;
  } else if (targetDate.getDate() === currentDate.getDate()) {
    return `Today at ${time}`;
  } else {
    return `${formattedDateServer(new Date(timestamp))} at ${time}`;
  }
}
export const getMonthDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = dateString.toLocaleDateString("en-US", options);
  return formattedDate;
};
// export function generateTimeArray(skDate) {
//   const timeArray = [];
//   const now = new Date();
//   const specificDate = new Date(skDate);

//   let startHour;
//   if (specificDate.getDate() > now.getDate()) {
//     startHour = new Date(
//       specificDate.getFullYear(),
//       specificDate.getMonth(),
//       specificDate.getDate(),
//       10,
//       0,
//       0
//     );
//   } else {
//     startHour = new Date(
//       now.getFullYear(),
//       now.getMonth(),
//       now.getDate(),
//       now.getHours() >= 6 ? now.getHours() : 6,
//       0,
//       0
//     );
//   }
//   for (let i = 0; i < 24; i++) {
//     const currentTime = new Date(startHour.getTime() + i * 60 * 60 * 1000);

//     const hourString = currentTime.toLocaleString("en-US", {
//       hour: "numeric",
//       hour12: false,
//     });
//     const timeString = currentTime.toLocaleString("en-US", {
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//       hour12: true,
//     });

//     timeArray.push({
//       hour: hourString,
//       time: timeString,
//     });
//   }

//   const currentTimeIndex = now.getHours();
//   // console.log(currentTimeIndex, "currentTimeIndex");
//   // console.log(
//   //   now,
//   //   "now",
//   //   now.toLocaleString("en-US", {
//   //     hour: "numeric",
//   //     minute: "numeric",
//   //     second: "numeric",
//   //     hour12: true,
//   //   })
//   // );
//   // console.log(timeArray, "timeArray");

//   // const filteredTimeArray = timeArray.filter((timeObj, index) => {
//   //   if (specificDate.getDate() > now.getDate()) {
//   //     return true;
//   //   }

//   //   if (specificDate.getDate() === now.getDate()) {
//   //     if (index === currentTimeIndex) {
//   //       return true;
//   //     }
//   //     // console.log(
//   //     //   timeObj.hour >
//   //     //     now.toLocaleString("en-US", {
//   //     //       hour: "numeric",
//   //     //       hour12: false,
//   //     //     }),
//   //     //   "oondajdlfkajdfg"
//   //     // );
//   //     return (
//   //       timeObj.hour >
//   //       now.toLocaleString("en-US", {
//   //         hour: "numeric",
//   //         hour12: false,
//   //       })
//   //     );
//   //   }

//   //   return false;
//   // });
//   // console.log(filteredTimeArray, "filteredTimeArray");
//   const filteredTimeArray = timeArray.filter((timeObj) => {
//     const hour = parseInt(timeObj.hour, 10);
//     if (hour >= 10 && hour < 21) {
//       return true;
//     }
//     return false;
//   });

//   return filteredTimeArray;
// }
// export function generateTimeArray(skDate) {
//   const timeArray = [];
//   const now = new Date();
//   const specificDate = new Date(skDate);

//   let startHour;
//   if (specificDate.getDate() > now.getDate()) {
//     startHour = new Date(
//       specificDate.getFullYear(),
//       specificDate.getMonth(),
//       specificDate.getDate(),
//       10,
//       0,
//       0
//     );
//   } else {
//     startHour = new Date(
//       now.getFullYear(),
//       now.getMonth(),
//       now.getDate(),
//       now.getHours() >= 10 ? now.getHours() : 10,
//       0,
//       0
//     );
//     // console.log(startHour, "starthoure");
//   }

//   for (let i = 0; i < 20; i++) {
//     const currentTime = new Date(startHour.getTime() + i * 60 * 60 * 1000);

//     const hour = currentTime.getHours();
//     const minute = currentTime.getMinutes();

//     if (hour >= 10 && hour <= 20) {
//       const timeString = currentTime.toLocaleString("en-US", {
//         hour: "numeric",
//         minute: "numeric",
//         second: "numeric",
//         hour12: true,
//       });

//       timeArray.push({
//         hour: hour,
//         time: timeString,
//       });
//     }
//   }
//   console.log(timeArray, "timeArray");
//   const filteredTimeArray = timeArray.filter((timeObj) => {
//     const hour = parseInt(timeObj.hour, 10);
//     // console.log(hour, "hour");
//     // console.log(hour > now.getDate() && hour <= 20, "hour >= 10 && hour <= 20");
//     if (hour > now.getDate() && hour <= 20) {
//       return true;
//     } else if (hour >= 10 && hour <= 20) {
//       return true;
//     } else return false;
//   });
//   setTimeout(() => {
//     console.log(filteredTimeArray, "hour");
//   }, 3000);

//   return filteredTimeArray;
// }
export function generateTimeArray(skDate) {
  const timeArray = [];
  const now = new Date();
  const specificDate = new Date(skDate);

  let startHour;

  if (specificDate > now) {
    // Case 1: skDate is in the future
    startHour = new Date(
      specificDate.getFullYear(),
      specificDate.getMonth(),
      specificDate.getDate(),
      10,
      0,
      0
    );
  } else {
    // Case 2: skDate is today or in the past
    startHour = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours() > 10 ? now.getHours() : 10,
      0,
      0
    );
  }

  for (let i = 0; i < 20; i++) {
    const currentTime = new Date(startHour.getTime() + i * 60 * 60 * 1000);

    const hour = currentTime.getHours();

    // Only add hours between 10 and 20, or after the current hour
    if (specificDate > now) {
      if (hour > 10 && hour <= 20) {
        const hourString = currentTime.toLocaleString("en-US", {
          hour: "numeric",
          hour12: false,
        });
        const timeString = currentTime.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        });

        timeArray.push({
          hour: hourString,
          time: timeString,
        });
        // console.log(timeArray, "pecificDate > now");
      }
    }

    if (
      specificDate < now &&
      hour > now.getHours() + 1 &&
      hour > 10 &&
      hour <= 20
    ) {
      const hourString = currentTime.toLocaleString("en-US", {
        hour: "numeric",
        hour12: false,
      });
      const timeString = currentTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });

      timeArray.push({
        hour: hourString,
        time: timeString,
      });
      // console.log(timeArray, "timeArray");
    }
  }

  return timeArray;
}
export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 0).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export function getRandColor(brightness) {
  // Six levels of brightness from 0 to 5, 0 being the darkest
  var rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
  var mix = [brightness * 10, brightness * 1, brightness * 0]; // 51 => 255/5
  var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(
    function (x) {
      return Math.round(x / 2.0);
    }
  );

  return "rgb(" + mixedrgb.join(",") + ")";
}

export const htmlParser = (data) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  const text = doc.body.textContent || doc.body.innerText || ""; // Extracts plain text
  const plainText = text?.slice(0, 160);
  return plainText;
};



export function encryptKey(passkey) {
  return btoa(passkey);
}

export function decryptKey(passkey) {
  return atob(passkey);
}