import { Box, Typography, IconButton, Divider } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";

const MobileComponent = ({ text }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const route = useLocation()
    .pathname?.split("/")
    ?.slice(1)
    ?.at(0)
    ?.replace("-", " ")
    ?.replace("e_", " ");
  // const isRoute = route?.charAt(0)?.toUpperCase() + route?.slice(1);
  const isTitleName = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  return (
    <nav>
      <Box
        sx={{
          display:
            pathname === "/" || pathname === "/e_home-page" // ||
              ? // pathname === "/order-page"
                "none"
              : "flex",
          gap: 1,
          py: 1.5,
          px: 1,
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton
          aria-label="back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 400,
            fontFamily: "DM Serif",
            textTransform: "capitalize",
          }}
        >
          {isTitleName(route)}
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }}></Divider>
    </nav>
  );
};

export default MobileComponent;
