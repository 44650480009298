import React, { useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import {
  Home,
  LocalMall,
  ManageAccounts,
  ShoppingCart,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
// import { handleProfileOpen } from "../../Redux/ProfileSlice";

export default function BottomNavigationBar() {
  const [value, setValue] = React.useState("Home");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { checked } = useSelector((state) => state.isOrders);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    setValue(
      (location.pathname === "/" && "Home") ||
        (location.pathname === "/e_home-page" && "Home") ||
        (location.pathname === "/order-page" && "Order History") ||
        (location.pathname === "/wishList-page" && "Wish List") ||
        (location.pathname === "/profile-page" && "Profile") ||
        (location.pathname === "/cart-page" && "Cart") ||
        (location.pathname === "/e_cart-page" && "Cart")
    );
  }, [location.pathname]);
  return (
    <BottomNavigation
      style={{
        overflow: "hidden",
        display: `${
          location.pathname === "/" ||
          location.pathname === "/e_home-page" ||
          location.pathname === "/order-page" ||
          location.pathname === "/wishList-page" ||
          location.pathname === "/profile-page" ||
          location.pathname === "/cart-page" ||
          location.pathname === "/e_cart-page"
            ? "flex"
            : "none"
        }`,
      }}
      sx={{
        width: "100%",
        bgcolor: "white.main ",
        zIndex: "1199",
        position: "sticky",
        bottom: 0,
        borderTop: 0.5,
        borderColor: "primary.main",
        "& .MuiBottomNavigationAction-root": {
          color: "secondary.main",
        },
        "& .Mui-selected": {
          color: "primary.main",
        },
        // "& .MuiSvgIcon-root": {
        //   color: "#fa5d29",
        // },
      }}
      value={value}
      onChange={handleChange}
    >
      <BottomNavigationAction
        label="Home"
        value="Home"
        onClick={() =>
          checked ? navigate("/e_home-page") : navigate("/")
        }
        sx={{
          color: `${value === "Home" ? "#fa5d29" : "secondary.main"}`,
          minWidth: "25px",
        }}
        icon={<Home />}
      />
      {/* <BottomNavigationAction
        label="OurStores"
        value="Near stores"
        onClick={() => navigate("/stores-page")}
        sx={{
          color: `${value === "Near stores" ? "#fa5d29" : "secondary.main"}`,
          minWidth: "25px",
        }}
        icon={<Storefront />}
      /> */}
      <BottomNavigationAction
        label="Order "
        value="Order History"
        onClick={() => navigate("/order-page")}
        sx={{
          color: `${value === "Order History" ? "#fa5d29" : "secondary.main"}`,
          minWidth: "25px",
        }}
        icon={<LocalMall />}
      />
      <BottomNavigationAction
        label="Cart"
        onClick={() =>
          checked ? navigate("/e_cart-page") : navigate("/cart-page")
        }
        value="Cart"
        sx={{
          color: `${value === "Cart" ? "#fa5d29" : "secondary.main"}`,
          minWidth: "25px",
        }}
        icon={<ShoppingCart />}
      />{" "}
      <BottomNavigationAction
        label="Profile"
        value="Profile"
        onClick={() => navigate("/profile-page")}
        sx={{
          color: `${value === "Profile" ? "#fa5d29" : "secondary.main"}`,
          minWidth: "25px",
        }}
        icon={<ManageAccounts />}
      />
    </BottomNavigation>
  );
}
