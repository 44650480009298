import { Mail, Person } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { registerUsers } from "../../Redux/festures/userSlice";
import { handleAlert } from "../../Redux/alertSlice";
import { encryptKey } from "../../Utils/dataFunction";

const Register = ({
  setClick,
  setOtpVerify,
  setIsShow,
  sendOTP,
  mobileNumber,
}) => {
  const dispatch = useDispatch();

  const handleSubmitRegister = (values) => {
    // console.log(values, "values");
    // setIsShow(false);
    // setOtpVerify(true);
    const check = false;
    dispatch(
      registerUsers({
        url: `${process.env.REACT_APP_API}/register`,
        data: {
          ...values,
          ...mobileNumber,
          hashKey: process.env.REACT_APP_HASHKEY,
          customerId: localStorage.getItem("isUserIdDummy") || "",
        },
      })
    ).then((data) => {
      // console.log(data, "data");
      if (data?.payload?.success) {
        dispatch(
          registerUsers({
            url: `${process.env.REACT_APP_API}/sendOtp`,
            data: {
              ...values,
              ...mobileNumber,
              check: check,
            },
          })
        ).then((isData) => {
          // console.log(isData, "isData");
          dispatch(
            handleAlert({
              isOpen: true,
              type: isData?.payload?.success ? "success" : "error",
              msg: isData?.payload?.message,
            })
          );
          if (isData?.payload?.success) {
            localStorage.setItem("isOtp", encryptKey(isData?.payload?.otp));
            setIsShow(false);
            setOtpVerify(true);
            sendOTP();
            localStorage.setItem("isUserId", data?.payload?.data?._id);
            localStorage.setItem("isToken", data?.payload?.data?.token);
            localStorage.setItem(
              "credential",
              JSON.stringify({
                phoneNumber: data?.payload?.data?.phoneNumber,
                check: check,
              })
            );
          }
        });
      }
    });
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const isMobleSchemaRegister = yup.object().shape({
    fullName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),
    email: yup.string(),
  });
  const initialValuesRegister = {
    fullName: "",
    email: "",
  };
  // console.log(mobileNumber, "mobileNumber");
  return (
    <>
      <Formik
        onSubmit={handleSubmitRegister}
        initialValues={initialValuesRegister}
        validationSchema={isMobleSchemaRegister}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Typography
              variant="h2"
              color="initial"
              sx={{
                fontWeight: 900,
                fontFamily: "Inter",
                fontSize: { xs: 50, sm: 50, md: 73, xl: 73 },
                letterSpacing: 3.65,
              }}
            >
              WELCOME
            </Typography>
            <Typography
              variant="h6"
              color="initial"
              sx={{ fontWeight: 800, fontFamily: "Inter" }}
            >
              Sign Up
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 2,
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontWeight: 600,
                  color: "secondary.main",
                }}
              >
                Phone Number
              </Typography>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontWeight: 700,
                  color: "primary.main",
                }}
              >
                {mobileNumber?.phoneNumber}{" "}
              </Typography>
              <Button
                variant="text"
                color="primary"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setClick(true);
                }}
              >
                change
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "primary.main",
                }}
              >
                Full name
              </Typography>

              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                type={"text"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                placeholder="Enter your full name"
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: { xs: 280, sm: 280, md: 400, xl: 400 },
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      {/* <SearchIcon /> */}
                      <Person sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "primary.main",
                }}
              >
                Email
              </Typography>

              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                // required
                autoComplete="off"
                type={"email"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                placeholder="Enter Your Email"
                sx={{
                  "& .MuiInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: { xs: 280, sm: 280, md: 400, xl: 400 },
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      {/* <SearchIcon /> */}
                      <Mail sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            {/* <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "primary.main",
                }}
              >
                mobile number
              </Typography>

              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                type={"number"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                placeholder="enter your mobile number"
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: 400,
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      <Person sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box> */}

            <Button
              type="submit"
              // onClick={() => setClick(false)}
              disabled={
                (touched.fullName && errors.fullName) ||
                (touched.phoneNumber && errors.phoneNumber)
              }
              variant="contained"
              style={{
                backgroundColor:
                  "radial-gradient(4929.63% 91.67% at 5.43% 21.30%, #C42524 0%, #FB6867 100%)",
              }}
              sx={{
                width: "40%",
                textTransform: "capitalize",
                mt: 4,
                borderRadius: 61,
                color: "white.main",

                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              }}
            >
              Submit
            </Button>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                mt: 1.5,
              }}
            >
              <Typography
                variant="body1"
                color="black"
                sx={{ fontSize: 15, fontWeight: 400 }}
              >
                Already have an account?
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => setClick(true)}
                sx={{ textTransform: "capitalize" }}
              >
                Login now
              </Button>
            </Box> */}
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Register;
