import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Call, Mail, Person } from "@mui/icons-material";
import { registerUsers } from "../../Redux/festures/userSlice";
import { useDispatch } from "react-redux";
import { handleAlert } from "../../Redux/alertSlice";
import { encryptKey } from "../../Utils/dataFunction";
const Login = ({
  setClick,
  setOtpVerify,
  setIsShow,
  sendOTP,
  setMobileNumber,
  mobileNumber,
}) => {
  // console.log(mobileNumber?.phoneNumber, "mobileNumberlogin");
  const dispatch = useDispatch();
  const handleSubmitMobile = (values) => {
    // console.log(values, "values");
    // setClick(false);
    // setIsShow(false);
    // setOtpVerify(true);
    const check = true;
    dispatch(
      registerUsers({
        url: `${process.env.REACT_APP_API}/login/customer`,
        data: {
          ...values,
          hashKey: process.env.REACT_APP_HASHKEY,
          customerId: localStorage.getItem("isUserIdDummy") || "",
          customerFcmToken: localStorage.getItem("customerFcmToken"),
        },
      })
    ).then((data) => {
      // console.log(data, "data");
      if (!data?.payload?.success) {
        dispatch(
          handleAlert({
            isOpen: data?.payload?.message === "User Not Found" ? false : true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.message,
          })
        );
        setMobileNumber(values);
        setClick(false);
      }
      if (data?.payload?.success) {
        dispatch(
          registerUsers({
            url: `${process.env.REACT_APP_API}/sendOtp`,
            data: {
              ...values,
              check: check,
            },
          })
        ).then((isData) => {
          // console.log(isData, "isData");

          dispatch(
            handleAlert({
              isOpen: true,
              type: isData?.payload?.success ? "success" : "error",
              msg: isData?.payload?.message,
            })
          );
          if (isData?.payload?.success) {
            localStorage.setItem("isOtp",  encryptKey(isData?.payload?.otp));
            setIsShow(false);
            setOtpVerify(true);
            sendOTP();
            localStorage.setItem("isUserId", data?.payload?.data?._id);
            localStorage.setItem("isToken", data?.payload?.data?.token);
            localStorage.setItem(
              "credential",
              JSON.stringify({
                phoneNumber: data?.payload?.data?.phoneNumber,
                check: check,
              })
            );
          }
        });
      }
    });
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),
    email: yup.string(),
    // .matches(phoneRegex, "Invalid phone")
    // .required("Phone is required"),
  });
  const initialValues = {
    phoneNumber: (mobileNumber && mobileNumber?.phoneNumber) || "",
    email: "",
  };
  return (
    <>
      <Formik
        onSubmit={handleSubmitMobile}
        initialValues={initialValues}
        validationSchema={isMobleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Typography
              variant="h2"
              color="initial"
              sx={{
                fontWeight: 900,
                fontFamily: "Inter",
                fontSize: { xs: 35, sm: 35, md: 50, xl: 50 },
                letterSpacing: 3.65,
              }}
            >
              WELCOME
            </Typography>
            <Typography
              variant="h6"
              color="initial"
              sx={{ fontWeight: 800, fontFamily: "Inter" }}
            >
              Sign In
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "primary.main",
                }}
              >
                Phone
              </Typography>

              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                type={"number"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                placeholder="Enter Your Phone Number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                sx={{
                  "& .MuiInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: { xs: 280, sm: 280, md: 400, xl: 400 },
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      {/* <SearchIcon /> */}
                      <Call sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "primary.main",
                }}
              >
                Email
              </Typography>

              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                // required
                autoComplete="off"
                type={"email"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                placeholder="Enter Your Email"
                sx={{
                  "& .MuiInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: { xs: 280, sm: 280, md: 400, xl: 400 },
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      {/* <SearchIcon /> */}
                      <Mail sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>

            <Button
              type="submit"
              // onClick={() => setClick(false)}
              disabled={!!touched.phoneNumber && !!errors.phoneNumber}
              variant="contained"
              style={{
                backgroundColor:
                  "radial-gradient(4929.63% 91.67% at 5.43% 21.30%, #C42524 0%, #FB6867 100%)",
              }}
              sx={{
                width: "40%",
                textTransform: "capitalize",
                mt: 4,
                borderRadius: 61,
                color: "white.main",

                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              }}
            >
              Submit
            </Button>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                mt: 1.5,
              }}
            >
              <Typography
                variant="body1"
                color="black"
                sx={{ fontSize: 15, fontWeight: 400 }}
              >
                Already have an account?
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => setClick(false)}
                sx={{ textTransform: "capitalize" }}
              >
                Register
              </Button>
            </Box> */}
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Login;
