import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scroll_hook";
import { customTheme } from "./Utils/Theme";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { Store, persistStores } from "./Redux/Store";
import LoadingComponents from "./components/SkError/Loading";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<LoadingComponents />}>
    <HelmetProvider>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistStores}>
          <ThemeProvider theme={customTheme}>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </Suspense>
);
reportWebVitals();
